<template>
  <div class="d-flex flex-row pb-3 justify-content-center">
    <b-button class="icon" variant="outline"
              href="https://www.facebook.com/hair.by.astridm"
              target=”_blank”>
      <b-icon icon="facebook" aria-hidden="true"></b-icon>
    </b-button>
    <b-button class="icon" variant="outline" href="https://www.instagram.com/byastrid.beautysalon/"
              target=”_blank”>
      <b-icon icon="instagram" aria-hidden="true"></b-icon>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "Icons"
}
</script>

<style scoped lang="scss">

.icon {
  color: $secondary-dark;
  font-size: 26px;
}

.icon:hover {
  color: $secondary;
}

</style>