<template>
  <b-container>
    <b-img
        :src="require(`@/assets/${this.fileName}`)"
        :class="{large, 'small': !large}"
        alt="Astrid Beauty Logo"
    />
  </b-container>
</template>

<script>
export default {
  name: "Logo",
  props: {
    large: {
      type: Boolean
    },
    text: {
      type: Boolean
    }
  },

  computed: {
    fileName() {
      if (this.text) {
        return 'astrid-logo.png'
      }
      return 'astrid-logo.png'
    }
  }
}
</script>

<style scoped lang="scss">
.large {
  max-width: 300px;
}

.small {
  max-width: 200px;
}
</style>
