<template>
  <div class="outer">
    <b-container class="py-5 d-flex align-items-center flex-column">
      <div class="title-color">
        <h2 class="title">ABOUT</h2>
      </div>
      <div class="intro">
        Hi, I’m Astrid and I have been in the beauty industry since I was 18.
        Graduated from Chantilly HS Academy with my cosmetology license and ever
        since I have been doing hair. I started working for a salon on May of
        2016, where two years after working with them I got promoted to become
        an ASL and later a Color Specialist. And now this year God gave me the
        opportunity to become a Salon owner. I’m proud to be the owner of By
        Astrid Beauty Salon at the age of 22.
      </div>
      <image-trinity class="pt-5"/>
      <b-container class="d-flex justify-content-center">
        <b-button class="gallery-btn btn btn-primary btn-lg" type="button"
                  href="https://www.instagram.com/byastrid.beautysalon/" target="_blank">See more of my work on
          Instagram!
        </b-button>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import ImageTrinity from "@/components/home/image-trinity";

export default {
  name: "About",
  components: {ImageTrinity},
  mounted() {
    window.scrollTo(0, 0)
  }
};
</script>

<style scoped lang="scss">
.outer {
  background-color: $background-accent;
}

.intro {
  max-width: 800px;
  font-family: 'Montserrat', sans-serif;
}

.gallery-btn {
  margin-top: 20px;
  padding: 15px;
  font-family: 'Aboreto', cursive;
  border-radius: unset;
  letter-spacing: 1px;
  color: white;
  box-shadow: 5px 5px 15px 1px #c6c3cc;
}
</style>
