<template>
  <div class="outer">
    <b-container class="py-5 d-flex align-items-center flex-column">
      <div class="title-color">
        <h2 class="title">CONTACT US</h2>
      </div>
      <div class="d-flex flex-column">
        <Contact/>
        <Icons/>
      </div>
      <div class="map-container d-flex justify-content-center py-3">
        <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d387.81889029465594!2d-77.39985924968359!3d38.95708196600171!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1b779438214dcd6!2sby%20Astrid%20Beauty%20Hair%20Salon!5e0!3m2!1sen!2sus!4v1661479925404!5m2!1sen!2sus"
            width="400"
            height="250"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </b-container>

  </div>
</template>

<script>
import Contact from "@/components/Contact";
import Icons from "@/components/Icons";

export default {
  name: "ContactUs",
  components: {Icons, Contact},
  mounted() {
    window.scrollTo(0, 0)
  }
};
</script>

<style scoped lang="scss">
.outer {
  background-color: $background-accent;
  width: 100%;
}

.map {
  box-shadow: #c8c3da 5px 5px 15px 5px;
}
</style>
