import { render, staticRenderFns } from "./SlideButton.vue?vue&type=template&id=fd87e910&scoped=true&"
import script from "./SlideButton.vue?vue&type=script&lang=js&"
export * from "./SlideButton.vue?vue&type=script&lang=js&"
import style0 from "./SlideButton.vue?vue&type=style&index=0&id=fd87e910&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd87e910",
  null
  
)

export default component.exports