<template>
  <div class="outer">
    <b-container class="py-5 d-flex align-items-center flex-column">
      <div class="title-color">
        <h2 class="title">SERVICES</h2>
      </div>
      <service-section/>
    </b-container>
  </div>
</template>

<script>
import ServiceSection from "@/components/home/service-section";

export default {
  name: "Services",
  components: {ServiceSection},
  mounted() {
    window.scrollTo(0, 0)
  }
};
</script>

<style scoped lang="scss">
.outer {
  background-color: $background-accent;
}
</style>
