<template>
  <div v-if="$route.name !== 'home'" class="d-flex flex-wrap justify-content-around">
    <div class="mx-2 d-flex flex-column align-items-center"
         v-for="service in services"
         :key="service.name">
      <div class="icon-circle">
        <b-icon class="icon" :icon="service.icon" aria-hidden="true"></b-icon>
      </div>
      <div
          class="icon-card d-flex align-items-center justify-content-around">
        <span class="icon-title">{{ service.name }}</span>
      </div>
    </div>
    <SlideButton linkName="services" text="View Pricing" class="service-button"/>
  </div>

  <div v-else class="d-flex flex-wrap justify-content-around">
    <div class="mx-2 d-flex flex-column align-items-center"
         v-for="service in services.slice(0, 3)"
         :key="service.name">
      <div class="icon-circle">
        <b-icon class="icon" :icon="service.icon" aria-hidden="true"></b-icon>
      </div>
      <div
          class="icon-card d-flex align-items-center justify-content-around">
        <span class="icon-title">{{ service.name }}</span>
      </div>
    </div>
    <SlideButton linkName="services" text="View All Services" class="service-button"/>
  </div>
</template>

<script>
import services from "@/assets/services";
import SlideButton from "@/components/Buttons/SlideButton";

export default {
  name: "IconTrinity",
  components: {SlideButton},
  computed: {
    services() {
      return services;
    }
  }
};
</script>

<style scoped lang="scss">
.icon-circle {
  background-color: $secondary-dark;
  border-radius: 50%;
  padding: 15px;
  margin-bottom: -34px;
  z-index: 999;
}

.icon-card {
  width: 300px;
  height: 120px;
  background-color: white;
  box-shadow: 5px 5px 10px 5px #e3e1e7;
  margin: 15px;
  text-align: center;
}

.icon-title {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: $secondary-dark;
}

.icon {
  color: white;
  font-size: 22px;
}

.service-button {
  padding: 20px;
  margin-top: 15px;
  font-size: 20px;
}

</style>
