<template>
  <div>
    <b-container class="d-flex align-items-center flex-column hero-container">
      <h2 class="d-flex flex-column justify-content-center align-items-center pb-3 hero">
        <div class="header pb-2">Welcome to</div>
        <div class="title-color">
          <div class="sub-header">Astrid Beauty Salon</div>
        </div>
        <SlideButton text="Book Appointment" link="https://www.vagaro.com/byastridbeautysalon/services"
                     class="hero-button"/>
      </h2>
      <p class="text mt-2 mb-4">
        By Astrid is a luxury studio located in Herndon VA. We specialize in a wide range of services including
        balayage/foilayage, color correction , women’s and men’s cuts , waxing and make up for brides or any other
        special occasions.
      </p>
      <div class="text address d-flex justify-content-center flex-column pb-3">
        <span class="d-flex justify-content-center">13001 Worldgate Drive</span>
        <span class="d-flex justify-content-center">Suite #124</span>
        <span class="d-flex justify-content-center">Herndon, VA 20170</span>
        <span class="d-flex justify-content-center">(703) 786-3707</span>
        <a class="d-flex justify-content-center" href="mailto:byastridm@gmail.com">byastridm@gmail.com</a>
      </div>
      <span class="language pb-3">SE HABLA ESPAÑOL</span>
      <Icons/>
    </b-container>
  </div>
</template>

<script>
import Logo from "../Logo";
import IconTrinity from "./icon-trinity";
import Icons from "@/components/Icons";
import SlideButton from "@/components/Buttons/SlideButton";

export default {
  name: "AboutSection",
  components: {SlideButton, Icons, IconTrinity, Logo},
};
</script>

<style scoped lang="scss">
.hero {
  background-image: url('../../assets/images/single-stroke.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.hero-button {
  background-color: $secondary-dark;
}

.hero-button:hover {
  background-color: $secondary;
}

.hero-container {
  padding: 40px;
}

.button {
  padding-top: 10px;
  padding-bottom: 20px;
}

.title {
  text-align: center;
}
</style>
