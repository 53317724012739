<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="text address d-flex flex-column pb-3">
      <span class="text d-flex justify-content-center">13001 Worldgate Drive</span>
      <span class="text d-flex justify-content-center">Suite #124</span>
      <span class="text d-flex justify-content-center">Herndon, VA 20170</span>
    </div>

    <div class="text">
      <div class="pb-3 d-flex flex-row justify-content-center align-items-center">
        <b-icon icon="telephone" aria-hidden="true"></b-icon>
        <div class="pl-3">(703) 786-3707</div>
      </div>
      <div class="pb-3 d-flex flex-row justify-content-center align-items-center">
        <b-icon icon="envelope" aria-hidden="true"></b-icon>
        <a class="pl-3" href="mailto:byastridm@gmail.com">byastridm@gmail.com</a>
      </div>
      <p class="language">SE HABLA ESPAÑOL</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "Contact",
};
</script>

<style scoped lang="scss">
p {
  text-align: center;
}
</style>
