<template>
  <div class="outer">
    <b-container class="d-flex flex-column align-items-center py-5">
      <h2 class="title">CONTACT</h2>
      <Contact/>
    </b-container>
  </div>
</template>

<script>
import Contact from "@/components/Contact";

export default {
  name: "ContactSection",
  components: {Contact}
}
</script>

<style scoped lang="scss">
.outer {
  background-color: $background-accent;
  padding: 40px;
}

.summary {
  max-width: 60%;
}
</style>
