<template>
  <b-navbar class="navbar-main" sticky variant="success" type="light" toggleable="lg">
    <b-navbar-brand :to="{name: 'home'}">
      <h1>
        <logo/>
      </h1>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="nav-item py-2 px-3" :to="{ name: 'home' }" :class="{active: $route.name==='home'}">Home
        </b-nav-item>
        <b-nav-item exact-active-class="active" class="nav-item py-2 px-3" :to="{name: 'services'}"
                    :class="{active:
        $route.name==='services'}">Services
        </b-nav-item>
        <b-nav-item exact-active-class="active" class="nav-item py-2 px-3" :to="{name: 'about'}" :class="{active:
        $route.name==='about'}">About
        </b-nav-item>
        <b-nav-item exact-active-class="active" class="nav-item py-2 px-3" :to="{name: 'contactUs'}"
                    :class="{active:
         $route.name==='contactUs'}">Contact
        </b-nav-item>
        <SlideButton text="Book" link="https://www.vagaro.com/byastridbeautysalon/services" class="ml-3"/>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Logo from "./Logo";
import SlideButton from "@/components/Buttons/SlideButton";

export default {
  name: "Navbar",
  components: {SlideButton, Logo}
}
</script>

<style scoped lang="scss">

.nav-item {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 2px;

  &:hover {
    background-image: url('../assets/images/marbled-gold.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.active {
  background-image: url('../assets/images/marbled-gold.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.navbar-main {
  box-shadow: 5px 5px 10px 1px rgba(168, 158, 185, 0.35);
}
</style>
