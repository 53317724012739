<template>
  <b-container>
    <b-row class="pt-5 pb-3 footer">
      <b-col cols="12" lg="4" class="d-flex flex-column align-items-center">
        <div class="d-flex flex-column justify-content-left">
          <div class="text pb-3">
            <span class="d-flex justify-content-center">13001 Worldgate Drive</span>
            <span class="d-flex justify-content-center">Suite #124</span>
            <span class="d-flex justify-content-center">Herndon, VA 20170</span>
            <div class="d-flex justify-content-center">(703) 786-3707</div>
            <a class="d-flex justify-content-center" href="mailto:byastridm@gmail.com">byastridm@gmail.com</a>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="4" class="d-flex flex-column align-items-center">
        <Logo class="logo"/>
        <SlideButton text="Book Appointment"/>
        <Icons class="pt-3"/>
        <!--        <div id="google_translate_element"></div>-->
      </b-col>
      <b-col cols="12" lg="4" class="d-flex flex-column">
        <b-row class="text hours-section">
          <b-col cols="6" class="right">Sunday</b-col>
          <b-col cols="6">CLOSED</b-col>
          <b-col cols="6" class="right">Monday</b-col>
          <b-col cols="6">CLOSED</b-col>
          <b-col cols="6" class="right">Tuesday</b-col>
          <b-col cols="6">9:30 AM - 6:00 PM</b-col>
          <b-col cols="6" class="right">Wednesday</b-col>
          <b-col cols="6">9:30 AM - 4:00 PM</b-col>
          <b-col cols="6" class="right">Thursday</b-col>
          <b-col cols="6">9:30 AM - 6:00 PM</b-col>
          <b-col cols="6" class="right">Friday</b-col>
          <b-col cols="6">9:30 AM - 4:00 PM</b-col>
          <b-col cols="6" class="right">Saturday</b-col>
          <b-col cols="6">9:00 AM - 4:00 PM</b-col>
        </b-row>
      </b-col>
    </b-row>
    <GauntletDesignsPlug/>
  </b-container>
</template>

<script>
import GauntletDesignsPlug from "./GauntletDesignsPlug";
import Logo from "../Logo";
import Contact from "@/components/Contact";
import Icons from "@/components/Icons";
import SlideButton from "@/components/Buttons/SlideButton";

export default {
  name: "Footer",
  components: {SlideButton, Icons, Contact, Logo, GauntletDesignsPlug},
};
</script>

<style scoped lang="scss">
.footer {
  background-color: $success;
  min-height: 50px;
  color: $dark-text;
  padding: 20px 20px 0px 20px;
}

.logo {
  max-width: 220px;
  height: auto;
  padding-bottom: 20px;
}

.right {
  text-align: right;
}

.hours-section {
  width: 375px;
}
</style>
