<template>
  <div
      :class="{ 'white-background': $route.path === '/' }"
      class="shimmer icons d-flex flex-wrap pb-3 justify-content-center"
  >
    <div>
      <figure>
        <b-img
            class="trinity"
            :src="require(`@/assets/images/two-tone-hair.jpg`)"
            alt="Woman with dark brown hair and light blonde highlights, styled by Astrid "
        />
      </figure>
    </div>
    <div>
      <figure>
        <b-img
            class="trinity"
            :src="require(`@/assets/images/purple-hair.jpg`)"
            alt="Woman with purple hair, colored by Astrid"
        />
      </figure>
    </div>
    <div>
      <figure>
        <b-img
            class="trinity"
            :src="require(`@/assets/images/orange.jpg`)"
            alt="Woman with freshly cut and dyed hair by Astrid"
        />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: "imageTrinity",
};
</script>

<style scoped lang="scss">

.outer {
  background-color: $background-light
}

.white-background {
  background-color: $background-light
}

.trinity {
  width: 300px;
  height: 350px;
  box-shadow: 5px 5px 10px 1px #a89eb9;
  margin: 15px;
}

figure {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

figure:hover + span {
  bottom: -36px;
  opacity: 1;
}

.shimmer figure {
  position: relative;
}

.shimmer figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
          left,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.shimmer figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}
</style>
