<template>

  <div class="d-flex flex-column">
    <p class="d-flex justify-content-center company">© By Astrid Beauty Salon {{
        new Date().getFullYear()
      }} </p>
    <p class="d-flex justify-content-center created">
      Built by ©<a href="https://gauntletdesigns.com">&nbsp;Gauntlet Designs</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "GauntletDesignsPlug"
}
</script>

<style scoped lang="scss">
div {
  color: $dark-text;
}

.company {
  margin-bottom: 2px;
  font-size: 16px;
}

.created {
  font-size: 14px;
}
</style>
