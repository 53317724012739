<template>
  <b-container class="d-flex flex-column align-items-center">
    <IconTrinity class="py-5"/>
  </b-container>
</template>

<script>
import Contact from "@/components/Contact";
import IconTrinity from "@/components/home/icon-trinity";

export default {
  name: "ServiceSection",
  components: {Contact, IconTrinity}
}
</script>

<style scoped lang="scss">
.outer {
  background-color: $primary;
  padding: 40px;
}

.service-image {
  max-width: 600px;
  box-shadow: 5px 5px 10px 1px #a89eb9;
  margin: 15px;
}
</style>
