<template>
  <div id="app" class="d-flex flex-column layout">
    <Navbar/>
    <div class="app-container">
      <router-view/>
    </div>
    <Footer class="mt-auto"/>
  </div>
</template>

<style lang="scss">
.layout {
  min-height: 100vh;
}

.app-container {
  background-color: $background-accent;
  flex: 1;
}

.header {
  font-family: 'Aboreto', cursive;
  font-size: 35px;
  text-align: center;
}

.title {
  font-family: 'Aboreto', cursive;
  font-size: 35px;
  text-align: center;
}

.title-color {
  background-image: url('assets/images/single-stroke.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 25px;
}

.sub-header {
  font-family: 'Sacramento', cursive;
  font-size: 55px;
  text-align: center;
}

.text {
  max-width: 600px;
  font-family: 'Montserrat', sans-serif;
  color: $dark-text;
  text-align: center;
}

p {
  font-family: 'Montserrat', sans-serif;
  color: $dark-text;
}

a {
  color: cornflowerblue;
  font-family: 'Montserrat', sans-serif;
}

</style>
<script>
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";

export default {
  components: {Footer, Navbar}
}


</script>
