<template>
  <div>
    <AboutSection/>
    <image-trinity class="py-5"/>
    <Services/>
  </div>
</template>

<script>
import AboutSection from "../components/home/about-section";
import ContactSection from "../components/home/contact-section";
import ImageTrinity from "@/components/home/image-trinity";
import Services from "@/views/Services";

export default {
  name: "Home",
  components: {Services, ImageTrinity, AboutSection, ContactSection},

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="scss">
p {
  max-width: 600px;
}

</style>
