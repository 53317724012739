import Home from "@/views/Home";
import About from "@/views/About";
import ContactUs from "@/views/ContactUs";
import Services from "@/views/Services";

export default [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: ContactUs,
  },
  {
    path: "/services",
    name: "services",
    component: Services,
  },
];
