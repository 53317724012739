<template>
  <b-btn href="https://www.vagaro.com/byastridbeautysalon" target="_blank" class="mr-2 book-btn" size="lg" type="button"

  ><span>Book Now</span>
  </b-btn>
</template>

<script>
export default {
  name: 'SlideButton',
};
</script>

<style scoped lang="scss">
.book-btn {
  min-width: 180px;
  border-radius: 0;
  background-color: $secondary-dark;
  border: none;
  color: white;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 12px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  text-transform: uppercase;
}

.book-btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.book-btn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.book-btn:hover span {
  padding-right: 25px;
}

.book-btn:hover span:after {
  opacity: 1;
  right: 0;
}
</style>
