export default [
    {
        name: 'Cut & Style',
        icon: 'scissors'
    },
    {
        name: 'Color & Highlights',
        icon: 'brush'
    },
    {
        name: 'Eyelash Extension',
        icon: 'eye'
    },
    {
        name: 'Chemical Treatment',
        icon: 'palette'
    },
    {
        name: 'Conditioning Treatment',
        icon: 'stars',
    },
    {
        name: 'Waxing',
        icon: 'moon-stars'
    },
    {
        name: 'Massage',
        icon: 'music-note-list'
    },
    {
        name: 'Tanning',
        icon: 'brightness-alt-high'
    },
    {
        name: 'Skincare',
        icon: 'suit-heart'
    },
];
